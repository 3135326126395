.textbox {
  position: relative;
}

.textbox--loader {
  position: absolute;
  bottom: var(--sp-mediano);
  right: var(--sp-mediano);
  color: var(--color-azul-secundario);
  animation-name: loader-loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes loader-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.inputGroup {
  position: relative;
  margin-bottom: calc(var(--sp-mediano) * 1.5) !important;
}

.combobox label,
.inputGroup label {
  font-family: var(--font-gotham);
  font-size: 0.9rem;
  position: absolute;
  background: var(--color-blanco);
  padding: calc(var(--sp-chico) / 2) calc(var(--sp-chico) * 2)
    calc(var(--sp-chico) / 2) var(--sp-chico);
  top: -0.7em;
  left: var(--sp-mediano);
  border-radius: 3px;
  z-index: 3;
}
.inputGroup textarea,
.inputGroup select,
.inputGroup input {
  margin-bottom: var(--sp-chico);
}

.inputGroup textarea:focus,
.inputGroup select:focus,
.inputGroup input:focus {
  border-color: var(--color-azul) !important;
}
.inputGroup textarea:focus + label,
.inputGroup select:focus + label,
.inputGroup input:focus + label {
  color: var(--color-azul-secundario) !important;
}
.inputGroup .textbox--error-text {
  color: var(--color-rojo);
  font-weight: bold;
}

.inputGroup textarea:disabled + label,
.inputGroup select:disabled + label,
.inputGroup input:disabled + label {
  color: var(--color-gris);
}

.inputGroup .react-datepicker-popper {
  z-index: 4;
}

