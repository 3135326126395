.searchbar--overlay {
  width: 100%;
}

.searchbar__show > .searchbar--overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: var(--sp-grande);
}

.searchbar--container {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 28px;
  border: 2px solid #ccc;
}

.searchbar__show .searchbar--container {
  background-color: var(--color-blanco);
  padding: var(--sp-mediano);
  width: 100%;
  height: auto;
  border-radius: var(--sp-chico);
  flex-direction: column;
}
.searchbar__show .searchbar--container::before {
  display: inline;
  content: "Busqueda de reclamos";
  color: var(--color-negro);
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: left;
  font-family: var(--font-gotham);
}

.searchbar--desktop-input {
  font-family: var(--font-gotham);
  color: var(--color-negro);
  display: none;
}

.searchbar__show .searchbar--desktop-input {
  display: block;
  padding: var(--sp-chico);
}

.searchbar--desktop-input::placeholder {
  color: var(--color-gris-claro);
}

.searchbar--search-button {
  position: relative;
  border: none;
  color: var(--color-blanco);
  background: none;
  padding: 1px 6px 4px 3px;
  cursor: pointer;
  border-radius: 50%;
  left: calc(100% - var(--sp-grande));
}
.searchbar--search-button-mobile {
  display: none;
}
.searchbar__show .searchbar--search-button-mobile {
  display: block;
}

.searchbar__show .searchbar--search-button {
  display: none;
  margin-top: var(--sp-mediano);
  padding: var(--sp-mediano);
  border-radius: var(--sp-chico);
}

.searchbar--search-button:active {
  background: var(--color-azul);
}

.searchbar .searchbar--search-button__neutral {
  background-color: var(--color-azul);
}
.searchbar .searchbar--search-button__neutral:hover {
  background-color: var(--color-azul-secundario);
}

@media screen and (min-width: 450px) {
  .searchbar--overlay {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 28px;
    left: 0;
    padding: 0;
  }

  .searchbar--container {
    position: relative;
    display: flex;
    flex-direction: row !important;
    width: 100%;
    height: 28px;
    left: 0;
  }

  .searchbar--desktop-input {
    font-family: var(--font-gotham);
    color: var(--color-negro);
    display: none;
  }
  .searchbar--desktop-input {
    border: none;
    display: block;
    padding: 0 var(--sp-mediano);
    padding-top: 2px;
    width: 100%;
    border-radius: 3px 0 0 3px;
    box-sizing: content-box;
  }

  .searchbar--desktop-input:focus {
    border: 2px solid var(--color-verde);
  }

  .searchbar--search-button .icon {
    width: 18px;
  }

  .searchbar--search-button {
    position: initial;
    color: var(--color-negro);
    background: var(--color-verde);
    padding: 1px 20px 3px 20px;
    border-radius: 0 3px 3px 0;
    transition: background 0.2s;
  }

  .searchbar--search-button:hover {
    background: var(--color-azul);
    color: var(--color-blanco);
  }
}
