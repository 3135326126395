.combobox > select {
  width: 100%;
  padding: var(--sp-chico);
  border: 2px solid var(--color-negro);
  border-radius: var(--sp-chico);
  background: none;
}
.combobox > label {
  display: inline;
}
